<template>
  <v-card 
    elevation="0"
    style="margin-top:10px;padding: 0 10px;min-height: calc(100vh);"
  >
  <v-row v-if="!editedItem" style="padding: 0 10px;"
  >
    <v-col cols="12">
      <v-btn 
        color="primary"
        outlined
        block
        @click="handleAdd"
      >
        {{ $t('common.add', locale) }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col cols="12">
      <v-text-field
        v-model="editedItem.Name"
        :label="$t('dtouch.request.name', locale)"
        @input="handleChangeIsModified"
        outlined
        hideDetails
      />
      <!--translate
        type="text"
        v-model="editedItem.Name"
        :languages="languages"
        :label="$t('dtouch.request.name', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      /-->
    </v-col>
    <!--v-col cols="12">
      <translate
        type="text"
        v-model="editedItem.Description"
        :languages="languages"
        :label="$t('dtouch.request.description', locale)"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-col-->
    <v-col cols="12" style="padding: 25px;" v-if="!editedItem.ID">
      <select-image 
        :image="editedItem.Image"
        :onChange="handleChangeImage"
        :locale="locale"
        :folder="workspaceID"
      />
    </v-col>
    <v-col cols="12" style="padding: 0 25px;color:#CCCCCC;" v-if="!editedItem.ID">
      <p style="margin-bottom:0;padding-left:0;"><b><small style="font-style:italic;">Medidas de referencia:</small></b></p>
      <p style="margin-bottom:0;padding-left: 10px;line-height:1;"><small style="font-style:italic;margin-bottom:0; !important">Totem: 1080x980</small></p>
      <p style="margin-bottom:0;padding-left: 10px;line-height:1;"><small style="font-style:italic;margin-bottom:0 !important;">Mobile: 375x600</small></p>
      <p style="margin-bottom:0;padding-left: 10px;line-height:1;"><small style="font-style:italic;margin-bottom:0 !important;">Desktop: 1556x1076</small></p>
    </v-col>
    <v-col cols="12" style="padding: 25px;" v-if="editedItem.ID">
      <points-manager 
        :value="editedItem"
        :locale="locale"
        :languages="languages"
      />
    </v-col>
    <v-col cols="12">
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-col>
  </v-row>
  </v-card>
</template>
<script>
const defaultItem = {
  Name: '',
  Points: [],
  Image: null,
}
import apiDtouch from '@/services/apiDtouch'
//import Translate from '@/components/Translate'
import ActionButtons from '@/components/ActionButtons'
import SelectImage from '@/components/selectImage/Index'
import PointsManager from './pointsManager/Index'
export default {
  components: {
    //Translate,
    ActionButtons,
    SelectImage,
    PointsManager,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    editedID : {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
  }),
  watch: {
    editedID () {
      this.handleGetItem()
    },
  },
  methods: {
    handleAdd () {
      this.editedItem = JSON.parse(JSON.stringify(defaultItem))
      this.editedItem.WorkspaceID = this.workspaceID
    },
    handleGetItem () {
      apiDtouch.getItem (`v2/private/interactive-maps/${this.editedID}`)
        .then(response => {
          if (!response) return
          this.editedItem = JSON.parse(JSON.stringify(response))
        })
    },
    handleChangeImage (v) {
      if (!v) return 
      this.editedItem.newImage = v
      this.editedItem.Image = JSON.parse(JSON.stringify(v.base64))

      const img = new Image()
      img.onload = () => {
        this.editedItem.Width = img.width
        this.editedItem.Height = img.height
      }
      img.src = v.base64
      this.editedItem.LMD = new Date().toISOString()

      this.handleChangeIsModified()
    },
    handleChangeIsModified () {
      
    },
    handleDelete () {
      if (!this.editedItem.ID) return
      apiDtouch.deleteItem(`v2/private/interactive-maps/${this.editedItem.ID}`)
        .then(() => {
          this.onClose(true)
          this.handleClose(true)
        })
    },
    handleSave () {
      if (!this.editedItem.ID) {
        apiDtouch.addItem('v2/private/interactive-maps/', this.editedItem)
          .then(() => {
            this.onClose(true)
            this.handleClose()
          })
      } else {
        apiDtouch.updateItem(`v2/private/interactive-maps/${this.editedItem.ID}`, this.editedItem)
          .then(() => {
            this.onClose(true)
            this.handleClose()
          })
      }
    },
    handleClose () {
      this.onClose(false)
      this.editedItem = null
    }
  },
}
</script>


