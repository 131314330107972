<template>
  <v-layout wrap>
    <!--v-flex xs4>
    </v-flex>
    <v-flex xs4 style="height: 120px;">
      <select-image 
        :image="image"
        :onChange="handleChangeImage"
        :locale="locale"
        :imageWidth="1024"
        :imageHeight="768"
        :aspectRatio="16/9"
        :folder="workspaceID"
      />
    </v-flex>
    <v-flex xs4>
      <center>
      <v-btn
        @click="onDelete"
        style="margin-right: 20px;"
        color="red"
        dark
      >
        {{ $t('common.delete', locale) }}
      </v-btn>
      <v-btn
        @click="onClose"
        style="margin-right: 20px;"
      >
        {{ $t('common.close', locale) }}
      </v-btn>
      <v-btn
        color="primary"
        @click="handleSave"
      >
        {{ $t('common.save', locale) }}
      </v-btn>
      </center>
    </v-flex-->
    <v-flex xs12>
      <center>
        <canvas 
          ref="canvas"
          :width="width"
          :height="height"
          style="border:1px solid black;background-size: cover;"
          :style="{ backgroundImage: `url(${urlBackground})` }" 
        />
      </center>
    </v-flex>
  </v-layout>
  <!--v-card id="main-seats-map">
    <v-responsive :aspect-ratio="16/9">
      <v-card-text style="padding: 0;">
        <canvas 
          ref="canvas" 
          width="1024"
          height="768"
        />
      </v-card-text>
    </v-responsive>
  </v-card-->
</template>
<script>
//import Translate from '@/components/Translate'
import { fabric } from 'fabric'
import utils from '@/services/utils'
export default {
  components: {
  },
  props: {
    seatMapBackground: {
      type: String,
      default: null,
    },
    editedItem: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    lmu: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    canvas: null,
    image: null,
    background: null,
    urlBackground: null,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('booking')
    },
  },
  watch: {
    /*canvas (v) {
      this.handleChange(v)
    },*/
    editedItem () {
      this.prepareItem()
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.canvas = new fabric.Canvas(this.$refs.canvas)
      //this.canvas.setBackgroundColor('#cccccc80')

      // Create a new instance of the Image class
      this.background = new Image()
      // When the image loads, set it as background image
      this.background.onload = () => {
          //var f_img = new fabric.Image(this.background)
         // this.canvas.setBackgroundImage(f_img)
          this.canvas.renderAll()
      }

      this.prepareItem()
    },
    prepareItem () {
      this.canvas.remove(...this.canvas.getObjects())
      this.canvas.on('object:modified', this.handleChange)
      if (this.seatMapBackground) {
        this.background.src = this.seatMapBackground
        this.urlBackground = this.seatMapBackground
      }
      for (const item of this.editedItem) {
        const w = item.W ? item.W : 30
        const h = item.H ? item.H : 30
        /*
          var circle = new fabric.Circle({
            fill: 'red',
            radius: (item.Width ? item.Width : 100)/2,
            top: item.Top ? item.Top : 0,
            left: item.Left ? item.Left : 0,
            angle: item.Angle ? item.Angle : 0,//.155944313037946
            scaleX: item.ScaleX ? item.ScaleX : 1,
            scaleY: item.ScaleY ? item.ScaleY : 1,
          })
          var text = new fabric.Text(item.Pos.toString(), {
            fontSize: 10,
            originX: 'center',
            originY: 'center',
            top: (item.Top ? item.Top : 0) +10,
            width: item.Width ? item.Width : 100,
            height: item.Height ? item.Height : 100,
          })
          item.c = new fabric.Group([ circle, text ], {
                      //width: item.Width ? item.Width : 200,
                      //height: item.Height ? item.Height : 200,
                      width: item.Width ? item.Width : 200,
                      height: item.Height ? item.Height : 200,
                      top: item.Top ? item.Top : 20,
                      left: item.Left ? item.Left : 0,
                      angle: item.Angle ? item.Angle : 0,//.155944313037946
                      scaleX: item.ScaleX ? item.ScaleX : 1,
                      scaleY: item.ScaleY ? item.ScaleY : 1,
          })
          .setControlsVisibility({ mtr: false })
          */
        var c = new fabric.Circle({
          left: 0,
          top: 0,
          fill: item.BGColor ? item.BGColor : '#5afffa',
          stroke: '#666',
          selectable: true,
          centeredScaling:true,
          padding:2,
          hasRotatingPoint: false,
          borderColor: 'black',
          cornerColor: 'black',
          radius: (w / 2),
        })

        var t = new fabric.Text(item.Pos.toString(), {
          fontFamily: 'Calibri',
          fill: item.Color ? item.Color : '#000000',
          fontSize: h*0.6,
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
          left: 0.5*w, top: 0.5*h,
        })

        item.c = new fabric.Group([c, t],{
            // any group attributes here
          top: item.Y * this.scale,
          left: item.X * this.scale,
          scaleX: this.scale,
          scaleY: this.scale,
        })
      .setControlsVisibility({
        bl: false,
        tl: false,
        tr: false,
        br: false,
        mt: false, // middle top disable
        mb: false, // midle bottom
        ml: false, // middle left
        mr: false, // I think you get it
        mtr: false,// rotate
      })

          this.canvas.add(item.c)
      }
/*
      for (const item of this.editedItem.filter(x => x.SeatMapImage && x.SeatMapImage.length > 0)) {
        new fabric.Image.fromURL(item.SeatMapImage, (oImg) => {
          // scale image down, and flip it, before adding it onto canvas
          item.cOLD = oImg.set({
            width: item.Width ? item.Width : 200,
            height: item.Height ? item.Height : 200,
            //top: item.Top ? item.Top : 5,
            //left: item.Left ? item.Left : 0,
            angle: item.Angle ? item.Angle : 0,//.155944313037946
            //scaleX: item.ScaleX ? item.ScaleX : 1,
            //scaleY: item.ScaleY ? item.ScaleY : 1,
            originX: 'center',
            originY: 'center',
          })
          .setControlsVisibility({ mtr: false })


          var text = new fabric.Text(utils.getTranslation(item.Name, this.locale), {
            fontSize: 10,
            originX: 'center',
            originY: 'center',
          })
          item.c = new fabric.Group([ oImg, text ], {
                      //width: item.Width ? item.Width : 200,
                      //height: item.Height ? item.Height : 200,
                      width: item.Width ? item.Width : 200,
                      height: item.Height ? item.Height : 200,
                      top: item.Top ? item.Top : 5,
                      left: item.Left ? item.Left : 0,
                      angle: item.Angle ? item.Angle : 0,//.155944313037946
                      scaleX: item.ScaleX ? item.ScaleX : 1,
                      scaleY: item.ScaleY ? item.ScaleY : 1,
          })
          .setControlsVisibility({ mtr: false })

          this.canvas.add(item.c)
          //this.canvas.add(oImg)
        })
        /*
        item.c = new fabric.Rect({
          fill: 'red',
          width: item.Width ? item.Width : 100,
          height: item.Height ? item.Height : 100,
          top: item.Top ? item.Top : 5,
          left: item.Left ? item.Left : 101 * x,
          angle: item.Angle ? item.Angle : 0,//.155944313037946
          scaleX: item.ScaleX ? item.ScaleX : 1,
          scaleY: item.ScaleY ? item.ScaleY : 1,
        })
        this.canvas.add(item.c)
        /
      }
    */
    },
    handleChange () {
      this.onChange(this.editedItem.map(item => { 
        if (!item.c) return item
        /*
        item.Point = item.c.toDataURL({
          format: 'png',
          quality: 0.8
        })*/
        const aux = JSON.parse(JSON.stringify(item.c))
        item.c = null
        item.Y = (aux.top / this.scale) | 0
        item.X = (aux.left / this.scale) | 0
        return item
        /*
        return {
          id: item.ID,
          Y: item.c.top,
          left: item.c.left,
          width: item.c.width,
          height: item.c.height,
          scaleX: item.c.scaleX,
          scaleY: item.c.scaleY,
          angle: item.c.angle,
        }*/
      }))
    },
  },
}
</script>


